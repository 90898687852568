import React from "react";
import CardCart from "../components/Card/CardCart";
import ButtonCheckout from "../components/UI/Buttons/ButtonCheckout";

import AmountBar from "../components/Card/AmountBar";

function Cart({ setActivePage, cart, forceUpdate, prices, sets }) {
    //console.log('cart displayed: ', cart);
  let prod = Object.values(cart.stored);
    
  // подсчитывает количество и стоимость товаров в корзине 
  // сделан без стейтов, т.к. все равно должен пересчитываться на каждый рендер
	let sumQuantity = 0, sumPrice = 0;

	// adding glycerine and nicotine to sets
	let sets6ml = 0, sets24ml = 0;
	let addedNiko15 = false, addedNiko3 = false;

	for (const product of prod) {
		if (sets.ml6.includes(product.categoryId)) {
			sets6ml += product.quantity;
		} else if (sets.ml24.includes(product.categoryId)) {
			sets24ml += product.quantity;
		}
	}

	prod.forEach(product => {
		sumQuantity += product.quantity;
		// sumPrice += product.price*product.quantity;
		let price;
		if (sets.ml6.includes(product.categoryId) && !addedNiko15) {
			price = (product.price + prices.gly75.price) + prices.niko15.price;
			sumPrice += (product.price + prices.gly75.price)*product.quantity + Math.ceil(sets6ml/5) * prices.niko15.price*5;
			addedNiko15 = true;

		} else if (sets.ml24.includes(product.categoryId) && !addedNiko3) {
			price = (product.price + prices.gly30.price) + prices.niko3.price;
			sumPrice += (product.price + prices.gly30.price)*product.quantity + Math.ceil(sets24ml/5) * prices.niko3.price*5;
			addedNiko3 = true;

		} else {
			if (sets.ml6.includes(product.categoryId)) {
				price = (product.price + prices.gly75.price) + prices.niko15.price;
				sumPrice += (product.price + prices.gly75.price)*product.quantity;

			} else if (sets.ml24.includes(product.categoryId)) {
				price = (product.price + prices.gly30.price) + prices.niko3.price;
				sumPrice += (product.price + prices.gly30.price)*product.quantity;

			} else {
				price = product.price;
				sumPrice += price * product.quantity;
			}
		}
		// sumPrice += price * product.quantity;
	});
				
	let cards = [];
	addedNiko15 = false; 
	addedNiko3 = false;

	prod.forEach(product => {
		const isSet = sets.ml6.includes(product.categoryId) || sets.ml24.includes(product.categoryId);

		let price, sumPrice = 0;

		if (sets.ml6.includes(product.categoryId) && !addedNiko15) {
			price = (product.price + prices.gly75.price) + prices.niko15.price;
			sumPrice += (product.price + prices.gly75.price)*product.quantity + Math.ceil(sets6ml/5) * prices.niko15.price*5;
			addedNiko15 = true;

		} else if (sets.ml24.includes(product.categoryId) && !addedNiko3) {
			price = (product.price + prices.gly30.price) + prices.niko3.price;
			sumPrice += (product.price + prices.gly30.price)*product.quantity + Math.ceil(sets24ml/5) * prices.niko3.price*5;
			addedNiko3 = true;

		} else {
			if (sets.ml6.includes(product.categoryId)) {
				price = (product.price + prices.gly75.price) + prices.niko15.price;
				sumPrice += (product.price + prices.gly75.price)*product.quantity;

			} else if (sets.ml24.includes(product.categoryId)) {
				price = (product.price + prices.gly30.price) + prices.niko3.price;
				sumPrice += (product.price + prices.gly30.price)*product.quantity;

			} else {
				price = product.price;
				sumPrice += price * product.quantity;
			}
		}

		let new_card = <CardCart
			className="taste__card"
			title={product.name}
			subtitle={product.description}
			price={price}
			sumPrice={sumPrice}
			cardSrc={product.imageURLMiniature}
			item={product}
			forceUpdate={forceUpdate}
			cart={cart}
			isSet={isSet}
		/>
		cards.push(new_card);
	});

    
	return (
		<div className="cart">
			<h1 className="title">Кошик</h1>

      <div style = {{"color": "darkred"}}>
        Зверніть увагу: у вартість наборів крім ароматизатора <br />
        включається вартість нікотину кратно 5 та вартість гліцерину.
      </div>
			<br />

      <AmountBar cart={cart} />

			<div className="cart__content">
				<div className="cart__items taste__body">
					{ cards }
				</div>
				{ Object.keys(cart.stored).length ? (
                <div className="cart__right">
					<div className="cart__right-totalCount">
						<span>{sumQuantity}</span> товарів
					</div>
					<div className="cart__right-sum">
						<span>{sumPrice}</span> грн
					</div>
					<ButtonCheckout onClick={() => setActivePage("checkout")}>
						Оформити замовлення
					</ButtonCheckout>
					<p className="cart__right-text">
						Вказана ціна не є остаточною. Остаточну ціну ви отримаєте від
						менеджера при підтверджені замовлення.
					</p>
				</div>
                ) : "" }
				
			</div>
		</div>
	);
}

export default Cart;
