import React from "react";
 
function CartComponent({ activePage, setActivePage, cart, prices, sets })
{
    let products = Object.values(cart.stored);

    let sumQuantity = 0, sumPrice = 0;
		let sets6ml = 0, sets24ml = 0;
		let addedNiko15 = false, addedNiko3 = false;

		// counting added nicotine first
		for (const product of products) {
			if (sets.ml6.includes(product.categoryId)) {
				sets6ml += product.quantity;
			} else if (sets.ml24.includes(product.categoryId)) {
				sets24ml += product.quantity;
			}
		}
	if (!prices.notUpdated) {
		products.forEach(product => {
			sumQuantity += product.quantity;

			let price;

			if (sets.ml6.includes(product.categoryId) && !addedNiko15) {
				price = (product.price + prices.gly75.price) + prices.niko15.price;
				sumPrice += (product.price + prices.gly75.price)*product.quantity + Math.ceil(sets6ml/5) * prices.niko15.price*5;
				addedNiko15 = true;

			} else if (sets.ml24.includes(product.categoryId) && !addedNiko3) {
				price = (product.price + prices.gly30.price) + prices.niko3.price;
				sumPrice += (product.price + prices.gly30.price)*product.quantity + Math.ceil(sets24ml/5) * prices.niko3.price*5;
				addedNiko3 = true;

			} else {
				if (sets.ml6.includes(product.categoryId)) {
					price = (product.price + prices.gly75.price) + prices.niko15.price;
					sumPrice += (product.price + prices.gly75.price)*product.quantity;

				} else if (sets.ml24.includes(product.categoryId)) {
					price = (product.price + prices.gly30.price) + prices.niko3.price;
					sumPrice += (product.price + prices.gly30.price)*product.quantity;

				} else {
					price = product.price;
					sumPrice += price * product.quantity;
				}
			}
		});
	}
        
    return (
        <div className="header__cart" onClick={() => setActivePage("cart")}>
            <div className="header__cart-content">
				<svg
				    xmlns="http://www.w3.org/2000/svg"
				    width="36"
				    height="36"
				    viewBox="0 0 36 36"
				    fill="none"
				>
				<path
				    d="M24 16.5V10.5C24 7.18629 21.3137 4.5 18 4.5C14.6863 4.5 12 7.18629 12 10.5V16.5M7.5 13.5H28.5L30 31.5H6L7.5 13.5Z"
				    stroke="white"
				    strokeWidth="3"
				    strokeLinecap="round"
				    strokeLinejoin="round"
                />
				</svg>
				<div className="header__cart-body">
				    <span>{ sumQuantity ? String(sumPrice)+" грн" : "Кошик" }</span>
				    <div>
				        <span>{ sumQuantity+" товарів" }</span>
				    </div>
				</div>
            </div>
        </div>
    );
}


export default CartComponent;
